export const list = [
    {
        "name": '项目信息',
        "router": '/project',
        "icon": 'project',
        "children": false
    },
    {
        "name": '项目设备',
        "router": '/equip',
        "icon": 'desktop',
        "children": [
            {
                "name": '设备列表',
                "router": '/projectEquip-list'
            },
            {
                "name": '分组管理',
                "router": '/equip-management'
            }
        ]
    },
    {
        "name": '规则引擎',
        "router": '/test',
        "icon": 'rocket',
        "children": false
    },
    {
        "name": '场景联动',
        "router": '/test1',
        "icon": 'branches',
        "children": false
    }
]
