<template>
    <div class="home">
        <Header />
        <div class="warpper">
            <Menu />
            <div style="flex: 1">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/views/Header"
import Menu from "./Slider"
export default {
    name: "Project",
    components: {
        Header,
        Menu
    }
}
</script>

<style lang="scss" scoped>
.warpper {
    display: flex;
}
</style>
